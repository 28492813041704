import * as Sentry from "@sentry/vue";
import useRuntimeEnvironment from "@shared/composables/config/useRuntimeEnvironment";
import { App } from "vue";

export function initSentry (app: App) {
  const {
    isDevelopment, isTest, currentEnvironment,
  } = useRuntimeEnvironment();

  Sentry.init({
    app,
    dsn: "https://6ec8de051a27c6fc1c00596e6212d3e2@o4506751627296768.ingest.sentry.io/4506751631294469",
    environment: currentEnvironment,
    enabled: !isDevelopment && !isTest,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["*"],
    replaysSessionSampleRate: isDevelopment ? 1.0 : 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
